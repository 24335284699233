import { FC } from "react";
import "./button.style.scss";

interface IButton {
  text: string;
  onClick: () => void;
}

export const Button: FC<IButton> = ({ text, onClick }) => {
  return (
    <div className="button" onClick={onClick}>
      {text}
    </div>
  );
};
