import { useEffect, useRef, useState } from "react";
import { SiteMain } from "./components/site-main/site-main.component";
import { OurServices } from "./components/our-services/our-services.componsnt";
import { Projects } from "./components/projects/projects.component";
import { NavBar } from "./components/nav-bar/nav-bar.component";
import { Contact } from "./components/contact/contact.component";

import "./App.scss";

function App() {
  const scrollRef1 = useRef<HTMLDivElement>(null);
  const scrollRef2 = useRef<HTMLDivElement>(null);
  const scrollRef3 = useRef<HTMLDivElement>(null);
  const scrollRef4 = useRef<HTMLDivElement>(null);

  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const executeScroll = (ref: number) => {
    switch (ref) {
      case 1:
        scrollRef1.current?.scrollIntoView({
          behavior: "smooth",
        });

        break;
      case 2:
        scrollRef2.current?.scrollIntoView({ behavior: "smooth" });

        break;
      case 3:
        scrollRef3.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 4:
        scrollRef4.current?.scrollIntoView({ behavior: "smooth" });
        break;
    }
    return ref;
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //console.log(scrollPosition);
  return (
    <div className="App">
      <div className={`navbar ${scrollPosition > 650 ? "show" : ""}`}>
        <NavBar
          scrollMe={(num: number) => executeScroll(num)}
          position={scrollPosition}
        />
      </div>
      <div className="main-section" ref={scrollRef1}>
        <SiteMain scrollMe={(num: number) => executeScroll(num)} />
      </div>
      <div className="services-section" ref={scrollRef2}>
        <OurServices />
      </div>
      <div className="projects-section" ref={scrollRef3}>
        <Projects />
      </div>
      <div className="contact-section" ref={scrollRef4}>
        <Contact />
      </div>
    </div>
  );
}

export default App;
