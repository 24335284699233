import { FC } from "react";

import "./service-card.styles.scss";

interface ICard {
  type: string;
  image: string;
}
export const Card: FC<ICard> = ({ type, image }) => {
  return (
    <div className="card-wrapper">
      <div className="icon">
        <img src={image}></img>
        {type}
      </div>
      {/* <div className="about-service">Long long time ago</div> */}
    </div>
  );
};
