import { Card } from "../service-card/service-card.componant";

import * as Web from "../../assets/web.png";
import * as Mobile from "../../assets/mobile.png";
import * as Desktop from "../../assets/desktop.png";
import * as Custom from "../../assets/custom.png";

import "./our-services.styles.scss";

export const OurServices = () => {
  return (
    <div className="services-wrapper">
      <div className="services-row">
        <Card image={Web.default} type="Websites" />
        <Card image={Mobile.default} type="Mobile" />
      </div>
      <div className="services-row">
        <Card image={Desktop.default} type="Desktop" />
        <Card image={Custom.default} type="Custom" />
      </div>
    </div>
  );
};
