import { FC } from "react";

import "./project-card.styles.scss";

interface IProjectCard {
  url: string;
  src: string;
}
export const ProjectCard: FC<IProjectCard> = ({ url, src }) => {
  const navMe = (site: string) => {
    window.open(site);
  };
  return (
    <div className="project-card-wrapper">
      <img src={src} onClick={() => navMe(url)}></img>
    </div>
  );
};
