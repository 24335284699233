import { ProjectCard } from "../project-card/project-card.component";
import * as ETP from "../../assets/etp.png";
import * as MWS from "../../assets/mws.png";
import "./projects.styles.scss";

export const Projects = () => {
  return (
    <div className="projects-wrapper">
      <ProjectCard src={ETP.default} url="https://etpcanada.ca" />
      <div className="divider"></div>
      <ProjectCard src={MWS.default} url="https://www.mstanleylaw.ca/" />
    </div>
  );
};
