import * as Logo from "../../assets/Pazzoli Software Inc.png";
import { ProjectCard } from "../project-card/project-card.component";
import "./contact.styles.scss";

export const Contact = () => {
  return (
    <div className="contact-wrapper">
      <img className="logo" src={Logo.default} alt="Pazzoli Logo"></img>
      <div className="info-wrapper">
        <div className="info">
          <p>Email:</p>
          <a href="mailto:inquiries@pazzoli.ca">Contact Us</a>
        </div>
        <div className="info">
          <p>Phone: 519 803-6949</p>
        </div>
      </div>
    </div>
  );
};
