import { FC } from "react";
import { Button } from "../button/button.component";

import "./site-main.style.scss";
import * as Logo from "../../assets/Pazzoli Software Inc.png";

interface ISiteCenter {
  scrollMe: (num: number) => number;
}
export const SiteMain: FC<ISiteCenter> = ({ scrollMe }) => {
  return (
    <div className="main-wrapper">
      <div className="center-wrapper">
        <img className="logo" src={Logo.default} alt="Pazzoli Logo"></img>
      </div>
      <div className="button-wrapper">
        <Button text="Services" onClick={() => scrollMe(2)} />
        <Button text="Projects" onClick={() => scrollMe(3)} />
        <Button text="Contact" onClick={() => scrollMe(4)} />
      </div>
    </div>
  );
};
