import { FC, useState, useEffect } from "react";

import "./nav-bar.styles.scss";

interface INavBar {
  scrollMe: (num: number) => number;
  position: number;
}
export const NavBar: FC<INavBar> = ({ scrollMe, position }) => {
  const handleClick = (item: number) => {
    scrollMe(item);
  };

  return (
    <div className="nav-bar-wrapper">
      <div
        className={`menu-btn ${position < 650 ? "show" : ""}`}
        onClick={() => scrollMe(1)}
      >
        Home
      </div>
      <div
        className={`menu-btn ${
          position > 650 && position < 1408 ? "show" : ""
        }`}
        onClick={() => scrollMe(2)}
      >
        Services
      </div>
      <div
        className={`menu-btn ${
          position > 1409 && position < 1949 ? "show" : ""
        }`}
        onClick={() => scrollMe(3)}
      >
        Projects
      </div>
      <div
        className={`menu-btn ${position > 1950 ? "show" : ""}`}
        onClick={() => scrollMe(4)}
      >
        Contact
      </div>
    </div>
  );
};
